import { useEffect, useState } from "react"
import { AccordionRow, ColorInput, RangeInput } from "../../components"
import { FormField } from "../../components/form-field";
import { StateManager, shortenFloat } from "../../util";
import { CUSTOM_TEXTURE_PROP, CustomTextureProps } from "../../scene/SceneManager";
import throttle from "lodash.throttle";

interface AdjustMaterialFormProps {
    showWarning?: boolean;
}

export const AdjustMaterialForm = ({ showWarning }: AdjustMaterialFormProps) => {
    const [roughness, setRoughness] = useState<number>(0.5);
    const [metalness, setMetalness] = useState<number>(0.5);
    const [colorFactor, setColorFactor] = useState<string>('#ff0000');

    useEffect(() => {
        const unsubCustomProp = StateManager.getInstance().subscribe(CUSTOM_TEXTURE_PROP, () => {
            const {
              colorFactor: customColorFactor,
              roughness: customRoughness,
              metalness: customMetalness
            } = StateManager.getInstance().getState(CUSTOM_TEXTURE_PROP) as CustomTextureProps
            setColorFactor(customColorFactor ?? colorFactor)
            setRoughness(customRoughness ?? roughness)
            setMetalness(customMetalness ?? metalness)
          })

        return () => {
            unsubCustomProp()
        }
    }, [])

    const handleColorFactorChange = (val: string) => {
        StateManager.getInstance().setState(CUSTOM_TEXTURE_PROP, {
          ...StateManager.getInstance().getState(CUSTOM_TEXTURE_PROP),
          colorFactor: val,
        } as CustomTextureProps)
    }
    
    const throttledHandleColorFactorChange = throttle(handleColorFactorChange, 200, {
        trailing: true,
        leading: false
    })
    
    const handleRoughnessChange = (val: number) => {
        StateManager.getInstance().setState(CUSTOM_TEXTURE_PROP, {
        ...StateManager.getInstance().getState(CUSTOM_TEXTURE_PROP),
        roughness: val,
        } as CustomTextureProps)
    }
    
    const handleMetalnessChange = (val: number) => {
        StateManager.getInstance().setState(CUSTOM_TEXTURE_PROP, {
        ...StateManager.getInstance().getState(CUSTOM_TEXTURE_PROP),
        metalness: val,
        } as CustomTextureProps)
    }
    

    return <>
    {
        showWarning
        ? <AccordionRow>
                <span>Select a mesh to modify its color</span>
            </AccordionRow>
        : <>
            <AccordionRow>
                <FormField label='Color'>
                    <ColorInput
                        value={colorFactor}
                        onChange={(color) => throttledHandleColorFactorChange(color)}
                    />
                </FormField>
            </AccordionRow>
            <AccordionRow>
                <FormField label='Roughness'>
                    <RangeInput
                        value={shortenFloat(roughness, 2)}
                        onChange={(value) => handleRoughnessChange(value)}
                    />
                </FormField>
            </AccordionRow>
            <AccordionRow>
                <FormField label='Metalness'>
                    <RangeInput
                        value={shortenFloat(metalness, 2)}
                        onChange={(value) => handleMetalnessChange(value)}
                    />
                </FormField>
            </AccordionRow>
        </>
    }
    </>
}
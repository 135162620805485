import React, { useEffect, useRef, useState } from 'react'
import './app.css';
import { Accordion, AccordionItemProps, Button, LoadingScreen } from './components';
import { AdjustMaterialForm, UploadImageForm, UploadStickerForm, SelectMaterialForm } from './forms';
import {
  CUSTOM_TEXTURE_PROP,
  DECAL_FLIP,
  DECAL_ROTATION,
  DECAL_SCALE,
  GTLF_FILE,
  IS_LOADING,
  MODE,
  Modes,
  ORIGIANL_IMAGE_MAP,
  ORIGINAL_MATERIAL_TEXTURES_MAP,
  PICKED_MATERIAL,
  SceneManager
} from './scene/SceneManager';
import { StateManager, debounce, getQueryParamValue, isInIframe, isVertical } from './util';

const getCanvasSize = (canvasContainer?: HTMLDivElement) => {
  const width = canvasContainer?.clientWidth;
  const height = canvasContainer?.clientHeight;

  if (!width || !height) {
    return {
      // maybe calculate based on container div size
      width: isVertical() ? window.innerWidth : window.innerWidth * 0.5,
      height: isVertical() ? window.innerHeight * 0.65 : window.innerHeight * 0.7
    }
  }

  return {
    width,
    height
  }
}

export const App = () => {
  const allowCustomDecal = JSON.parse(getQueryParamValue('decal') || 'false') as boolean;
  const allowCustomBaseColor = JSON.parse(getQueryParamValue('baseColor') || 'false') as boolean;
  const canvas = useRef<HTMLCanvasElement>(null)
  const canvasContainerRef = useRef<HTMLDivElement>(null)
  const [gltfFile, setGltfFile] = useState<File | null>(null)
  const [pickedMaterial, setPickedMaterial] = useState<THREE.Mesh | null>(null)
  const [vertical, setVertical] = useState(isVertical())
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    StateManager.getInstance().setState(MODE, Modes.View)

    if (allowCustomBaseColor) {
      StateManager.getInstance().setState(MODE, Modes.AdjustColor)
    } else {
      StateManager.getInstance().setState(MODE, Modes.ReplaceTexture)
    }

    /**
     * Handle messages from the parent window
     */
    const messageHandler = (event: any) => {
      if (event.data) {
        switch (event.data.type) {
          case 'LOAD_GLB':
            // convert blob to File
            const blob = new Blob([event.data.value], { type: 'application/octet-stream' });
            const file = new File([blob], 'test.glb', { type: 'application/octet-stream' });
            setGltfFile(file);
            break;
        }
      }
    }

    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler)
    }
  }, []) // there was no [] it should be ok but just to be sure

  useEffect(() => {
    if (canvas.current) {
      SceneManager.startScene(canvas.current)

      const handlePickedMesh = () => {
        const material = StateManager.getInstance().getState(PICKED_MATERIAL)
        setPickedMaterial(material)
      }

      StateManager.getInstance().setState(CUSTOM_TEXTURE_PROP, {})

      // send a message to the parent window
      window.parent.postMessage({
        type: 'CONFIGURATOR_READY',
        value: 'ready'
      }, '*')

      const handleWindowResize = () => {
        if (canvas.current && canvasContainerRef.current) {
          canvas.current.width = getCanvasSize(canvasContainerRef.current).width
          canvas.current.height = getCanvasSize(canvasContainerRef.current).height
        }
    
        setVertical(isVertical())
      }

      const handleDragOver = (e: DragEvent) => {
        e.preventDefault()
      }

      const unsubscribe = StateManager.getInstance().subscribe(PICKED_MATERIAL, handlePickedMesh)
      const unsubLoading = StateManager.getInstance().subscribe(IS_LOADING, () => {
        setIsLoading(StateManager.getInstance().getState(IS_LOADING))
      })
      window.addEventListener('resize', handleWindowResize)
      window.addEventListener('dragover', handleDragOver)

      return () => {
        SceneManager.destroyScene()
        unsubscribe()
        unsubLoading()
        window.removeEventListener('resize', handleWindowResize)
        window.removeEventListener('dragover', handleDragOver)
      }
    }
  }, [])

  useEffect(() => {
    if (gltfFile && canvas.current && canvasContainerRef.current) {
      SceneManager.destroyScene()
      SceneManager.startScene(canvas.current)
      StateManager.getInstance().setState(ORIGIANL_IMAGE_MAP, new Map())
      StateManager.getInstance().setState(ORIGINAL_MATERIAL_TEXTURES_MAP, new Map())
      StateManager.getInstance().setState(PICKED_MATERIAL, null)
      StateManager.getInstance().setState(GTLF_FILE, gltfFile)
      StateManager.getInstance().setState(DECAL_SCALE, 0.2)
      StateManager.getInstance().setState(DECAL_ROTATION, 0)
      StateManager.getInstance().setState(DECAL_FLIP, false)
    }
  }, [gltfFile])

  const debouncedHandleGlbSelect = debounce((e) => {
    const files = e?.dataTransfer?.files || e?.target?.files
    
    if (files && files.length > 0) {
      const file = files[0]
      if ((file.name as string).includes('.gltf') || (file.name as string).includes('.glb')) {
        setGltfFile(file)
      }
    }
  }, 1000)

  const handleGlbSelect = (e: any) => {
    e.preventDefault()
    debouncedHandleGlbSelect(e)
  }

  const getOptionalDecalTab = (): AccordionItemProps<any>[] => {
    return allowCustomDecal ? [
      {
        key: Modes.DecalOnTexture,
        title: 'Upload Sticker',
        render: <>
          <UploadStickerForm />
        </>,
        type: 'expandable'
      },
    ] : []
  }

  const getOptionalBaseColorTab = (): AccordionItemProps<any>[] => {
    return allowCustomBaseColor ? [
      {
        key: Modes.AdjustColor,
        title: 'Adjust Material',
        render: <>
          <AdjustMaterialForm showWarning={!Boolean(pickedMaterial)} />
        </>,
        type: 'expandable'
      },
    ] : []
  }


  return <div className={`container ${vertical ? 'container-vertical' : ''}`}>
    <div className='logo' ></div>
    <div className={`left-container ${vertical ? 'left-container-vertical' : ''} `}>
      <div ref={canvasContainerRef} className='canvas-container'>
        <canvas
          ref={canvas}
          width={getCanvasSize(canvasContainerRef.current ?? undefined).width}
          height={getCanvasSize(canvasContainerRef.current ?? undefined).height}
        />
      </div>
    </div>

    <div className={`right-container ${vertical ? 'right-container-vertical' : ''} `}>
      <Accordion
        defaultActiveIndex={1}
        onChange={(index: number, key) => {
          if (index !== -1) {
            StateManager.getInstance().setState(MODE, key)
            return;
          }

          StateManager.getInstance().setState(MODE, Modes.View)
        }}
        items={[
          {
            key: 'select-material',
            title: 'Select Material',
            renderTitle: <SelectMaterialForm />,
            type: 'inline'
          },
          ...getOptionalBaseColorTab(),
          {
            key: Modes.ReplaceTexture,
            title: 'Upload Image',
            render: <>
              <UploadImageForm showWarning={!Boolean(pickedMaterial)} />
            </>,
            type: 'expandable'
          },
          ...getOptionalDecalTab()
        ]}
      />
      <div className='confirm-button-container'>
        <Button label='Confirm' onClick={() => SceneManager.instance?.saveGltfModel()} />
      </div>
    </div>
      {
        // gltf file loader
        !gltfFile &&
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'white'}}>
          {
            isInIframe() ? <div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <div style={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '1rem' }}>Welcome to Mazing 3D Configurator</div>
                  <div style={{ fontSize: '1.5rem', marginBottom: '1rem' }}>waiting for a 3D model to be selected</div>
                </div>
              </div>
            </div> : <div onDrop={handleGlbSelect} style={{width: '100%', height: '100%'}}>
              <label onDrop={handleGlbSelect} htmlFor="images" className="drop-container">
                <span className="drop-title">Drop files here</span>
                or
                <input onChange={handleGlbSelect} type="file" id="images" accept=".glb,.gltf" required />
              </label>
            </div>
          }
        </div>
      }
      {
        isLoading && <LoadingScreen />
      }
  </div>
}
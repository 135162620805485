import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter'
import { DecalGeometry } from 'three/examples/jsm/geometries/DecalGeometry'
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils';

import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { StateManager, isInIframe, isSingleMaterial, isVertical, loadImageFromBitmap } from '../util';
import { loadGltfModel } from './loadGltfModel';
import _ from 'lodash';

export enum Modes {
    View = 'view',
    AdjustColor = 'adjustColor',
    ReplaceTexture = 'replaceTexture',
    DecalOnTexture = 'decalOnTexture',
}

export const IS_LOADING = 'isLoading';

export const PICKED_MATERIAL = 'pickedMaterial';
export const UNPICKED_MATERIAL = 'unpickedMaterial';
export const HOVERED_MATERIAL = 'hoveredMaterial';
export const UNHOVERED_MATERIAL = 'unhoveredMaterial';
export const GTLF_FILE = 'gltfFile';
export const MATERIAL_LIST = 'materialList';

export const IMAGE_FILE = 'imageFile';
export const DECAL_IMAGE_FILE = 'decalImageFile';

export const DECAL_MATERIAL = 'decalMaterial';
export const CUSTOM_TEXTURE_SET = 'customTextureSet';

export const CUSTOM_TEXTURE_FLIP = 'customTextureFlip';

export const CUSTOM_TEXTURE_PROP = 'customTextureProp';
export type CustomTextureProps = {
    scale: number;
    rotation: number;
    offset: {x: number, y: number};
    colorFactor: string;
    roughness: number | undefined;
    metalness: number | undefined;
}

export const DECAL_SCALE = 'decalScale';
export const DECAL_ROTATION = 'decalRotation';
export const DECAL_FLIP = 'decalFlip';

export const MODE = 'mode';

export const ORIGIANL_IMAGE_MAP = 'originalImageMap'
export const ORIGINAL_MATERIAL_TEXTURES_MAP = 'originalMaterialTexturesMap'

const TARGET_MODE_SIZE = 0.5;

const createDecalMaterial = (image: HTMLImageElement) => {
    // create a canvas element double the size of the image
    const canvas = document.createElement('canvas');
    const longSide = Math.max(image.width, image.height);
    canvas.width = longSide * 2;
    canvas.height = longSide * 2;

    // draw the image at the center of the canvas
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        console.error('Could not get canvas context');
        return;
    }

    // fit the image at the center of the canvas given that the
    // canvas is square and the image is not while keeping margins
    // around the image equal to half of the canvas size
    const imgWidth = image.width;
    const imgHeight = image.height;
    
    const imgRatio = imgWidth / imgHeight;
    const canvasRatio = canvas.width / canvas.height;

    let imgWidthToDraw = 0;
    let imgHeightToDraw = 0;

    if (imgRatio > canvasRatio) {
        imgWidthToDraw = canvas.width / 2;
        imgHeightToDraw = imgWidthToDraw / imgRatio;
    } else {
        imgHeightToDraw = canvas.height / 2;
        imgWidthToDraw = imgHeightToDraw * imgRatio;
    }

    const imgX = canvas.width / 2 - imgWidthToDraw / 2;
    const imgY = canvas.height / 2 - imgHeightToDraw / 2;

    ctx.drawImage(image, imgX, imgY, imgWidthToDraw, imgHeightToDraw);

    // create a canvas texture
    const texture = new THREE.CanvasTexture(canvas);

    return new THREE.MeshStandardMaterial({
        map: texture,
        transparent: true,
        depthTest: true,
        depthWrite: false,
        polygonOffset: true,
        polygonOffsetFactor: - 4,
        wireframe: false,
        // make it slightly shiny and reflective like a decal
        roughness: 0.5,
        metalness: 0.5,
        bumpScale: 0.5,
        side: THREE.FrontSide,
    });
}

const createDecalGeometry = (
    intersectedObject: THREE.Mesh,
    intersectedPoint: THREE.Vector3,
    intersectedFace: THREE.Face,
    scale: number,
    rotation: number,
) => {
    const position = intersectedPoint;
    const eye = position.clone();
    const normal = intersectedFace.normal.clone();
    normal.transformDirection( intersectedObject.matrixWorld );
    eye.add(normal);

    const decalRotation = new THREE.Matrix4();
    decalRotation.lookAt(eye, position, THREE.Object3D.DefaultUp);
    const euler = new THREE.Euler();
    euler.setFromRotationMatrix(decalRotation);

    // rotate the decal geometry around the normal going through the center of the decal by 45 degrees
    euler.z = rotation;

    return new DecalGeometry(
        intersectedObject as THREE.Mesh,
        position,
        euler,
        new THREE.Vector3(scale, scale, scale)
    );
}

const createDecalMesh = (
    decalMaterial: THREE.MeshStandardMaterial,
    decalGeometry: DecalGeometry,
    intersectedObject: THREE.Mesh,
    intersectedFace: THREE.Face,
    persist = false,
    side: THREE.Side
) => {
    const material = decalMaterial.clone();

    if (persist) {
        material.polygonOffset = false;
    }

    const scale = 1.0015;
    decalGeometry.scale(scale, scale, scale)

    const normal = intersectedFace.normal.clone();
    normal.transformDirection( intersectedObject.matrixWorld );

    // translate the decal geometry in the direction of the normal
    const translationDirection = side === THREE.FrontSide ? 1 : -1;
    const translationAmount = 0.001 * translationDirection;
    decalGeometry.translate(normal.x * translationAmount, normal.y * translationAmount, normal.z * translationAmount);


    material.polygonOffsetFactor = material.polygonOffsetFactor - 4;
    const decalMesh = new THREE.Mesh(decalGeometry, material);
    decalMesh.name = `decal-${THREE.MathUtils.generateUUID()}`;
    decalMesh.receiveShadow = true;
    decalMesh.frustumCulled = false;

    return decalMesh;
}

// type MapSupportingMaterials = THREE.MeshBasicMaterial | THREE.MeshLambertMaterial | THREE.MeshPhongMaterial | THREE.MeshStandardMaterial | THREE.MeshPhysicalMaterial;

export const DECAL_SCALE_CONSTS = {
    min: 0.01,
    max: 1,
    step: 0.001
}

export const SCALE_VALUE_CONSTS = {
    min: 0.01,
    max: 20,
    step: 0.01
}

export const ROTATION_VALUE_CONSTS = {
    min: -Math.PI,
    max: Math.PI,
    step: Math.PI / 45
}

export const OFFSET_VALUE_CONSTS = {
    min: -1,
    max: 1,
    step: 0.001,
}

type RGB = {
    r: number;
    g: number;
    b: number;
};

const hexToRgb = (hex: string): RGB | null => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

const drawColorFactor = (
    ctx: CanvasRenderingContext2D,
    canvasWidth: number,
    canvasHeight: number,
    colorFactorHex: string,
): void => {
    const imageData = ctx.getImageData(0, 0, canvasWidth, canvasHeight);
    const data = imageData.data;
    const colorFactor = hexToRgb(colorFactorHex);

    if (colorFactor) {
        for (let i = 0; i < data.length; i += 4) {
            data[i] = Math.min(data[i] * colorFactor.r / 255, 255); // Red channel
            data[i+1] = Math.min(data[i+1] * colorFactor.g / 255, 255); // Green channel
            data[i+2] = Math.min(data[i+2] * colorFactor.b / 255, 255); // Blue channel
        }
        ctx.putImageData(imageData, 0, 0);
    }
}


function drawPattern(
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    rotation: number,
    scale: number,
    offsetX: number,
    offsetY: number,
  ) {
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    const imgWidth = img.width * scale;
    const imgHeight = img.height * scale;
    const centerX = canvasWidth / 2;
    const centerY = canvasHeight / 2;
  
    // clear the canvas
    ctx.clearRect(0, 0, canvasWidth, canvasHeight);
  
    // save the current canvas state
    ctx.save();
  
    // translate to the center of the canvas
    ctx.translate(centerX, centerY);
  
    // rotate the canvas
    ctx.rotate(rotation);
  
    // repeat the image in all four directions
    for (let x = -(canvasWidth + imgWidth/2); x < (canvasWidth + imgWidth/2); x += imgWidth) {
      for (let y = -(canvasHeight + imgHeight/2); y < (canvasHeight + imgHeight/2); y += imgHeight) {
        ctx.drawImage(img, x + offsetX,
            y + offsetY,
            imgWidth + 4,
            imgHeight + 4);

        }
    }
  
    // restore the canvas state
    ctx.restore();
}

/**
 * A singleton class that manages the scene
 */
export class SceneManager {
    static instance: SceneManager | null = null;

    public static startScene(canvas: HTMLCanvasElement) {
        if (!SceneManager.instance) {
            StateManager.getInstance().setState(IS_LOADING, true);
            SceneManager.instance = new SceneManager(canvas);
            StateManager.getInstance().setState(IS_LOADING, false);
        }
    }

    public static destroyScene() {
        if (SceneManager.instance) {
            SceneManager.instance.destroyScene();
            SceneManager.instance = null;
        }
    }

    public static isSceneStarted() {
        return SceneManager.instance !== null;
    }

    private renderer: THREE.WebGLRenderer;
    private camera: THREE.PerspectiveCamera;
    private scene: THREE.Scene;
    private controls: OrbitControls;
    // an array holding all state subscriptions
    private stateSubscriptions: Array<() => void> = [];
    private raycaster = new THREE.Raycaster();
    private canvas: HTMLCanvasElement;

    private originalCanvasClone: Map<string, HTMLCanvasElement> = new Map();
    private currentColorFactor: Map<string, string> = new Map();
    
    private decalImage: HTMLImageElement | null = null;
    private decalMaterial: THREE.MeshStandardMaterial | null = null;
    private hoverDecalMesh: THREE.Mesh | null = null;
    
    private pointer = new THREE.Vector2();
    private prevPointer = new THREE.Vector2();
    private pointerDownPosition = new THREE.Vector2();
    private pointerUpPosition = new THREE.Vector2();

    private originalGltfSize: number | null = null;

    private modelContainer: THREE.Object3D | null = null;

    // private helperVector1 = new THREE.ArrowHelper(undefined, undefined, 1, new THREE.Color(255, 0, 0))

    // private helperVector2 = new THREE.ArrowHelper(undefined, undefined, 1, new THREE.Color(0, 255, 0))

    // private helperVector3 = new THREE.ArrowHelper(undefined, undefined, 1, new THREE.Color(0, 0, 255))
    
    constructor(canvas: HTMLCanvasElement) {
        this.canvas = canvas;

        /**
         * Core components
         */
        this.renderer = new THREE.WebGLRenderer({
            canvas,
            antialias: true,
            alpha: true,
        })

        this.renderer.outputEncoding = THREE.sRGBEncoding
        this.renderer.toneMapping = THREE.ACESFilmicToneMapping
        this.renderer.toneMappingExposure = 0.7

        this.camera = new THREE.PerspectiveCamera(45, canvas.width / canvas.height, 0.001, 1000)
        
        this.scene = new THREE.Scene()

        // this.scene.add(this.helperVector1)
        // this.scene.add(this.helperVector2)
        // this.scene.add(this.helperVector3)

        /**
         * Helpers
        */
        this.controls = new OrbitControls( this.camera, this.renderer.domElement )
        //controls.update() must be called after any manual changes to the camera's transform
        this.camera.position.set(50, 50, 50)
        this.camera.lookAt(new THREE.Vector3(0, 0, 0))
        this.controls.update();
        
        // const axesHelper = new THREE.AxesHelper(30)
        // this.scene.add(axesHelper)

        /**
         * Add scene background
         */
        const rgbeLoader = new RGBELoader()
        StateManager.getInstance().setState(IS_LOADING, true);
        rgbeLoader.load('/assets/neutral.hdr', (texture) => {
            StateManager.getInstance().setState(IS_LOADING, false);
            texture.mapping = THREE.EquirectangularReflectionMapping
            this.scene.environment = texture
        })

        /**
         * Add ambient light
         */
        // const ambientLight = new THREE.AmbientLight(0xffffff, 1)
        // this.scene.add(ambientLight)

        this.subscribeToStateChanges();
        this.initEventListeners();
        this.animate()
    }

    private initEventListeners() {
        /**
         * Update the aspect ration when window is
         * resized so that the scene is not squished.
         */
        window.addEventListener('resize', this.onWindowResize.bind(this));
        window.addEventListener('pointermove', this.onPointerMove.bind(this));
        this.canvas.addEventListener('pointerdown', this.onPointerDown.bind(this));
        this.canvas.addEventListener('pointerup', this.onPointerUp.bind(this));
    }

    private onWindowResize = () => {
        this.camera.aspect = this.canvas.width / this.canvas.height
        this.camera.updateProjectionMatrix()

        this.renderer.setSize(this.canvas.width, this.canvas.height)
        this.renderer.render(this.scene, this.camera)
    }

    private onPointerMove = (event: MouseEvent) => {
        // calculate mouse position in normalized device coordinates
        // (-1 to +1) for both components
        const canvasBoundingRect = this.canvas.getBoundingClientRect();
        const canvasX = canvasBoundingRect.left + window.scrollX;
        const canvasY = canvasBoundingRect.top + window.scrollY;

        this.pointer.x = ( (event.clientX - canvasX) / this.canvas.width ) * 2 - 1;
        this.pointer.y = - ( (event.clientY - canvasY) / this.canvas.height ) * 2 + 1;

        if (!this.prevPointer) {
            this.prevPointer = this.pointer.clone();
        }

        this.hoverPiece();
    }

    private onPointerUp = () => {
        this.pointerUpPosition.copy( this.pointer );
        this.raycaster.setFromCamera( this.pointer, this.camera );

        // calculate objects intersecting the picking ray
        const intersects = this.raycaster.intersectObjects( this.scene.children, true );
        const mode = StateManager.getInstance().getState(MODE);
        const filteredIntersects = intersects
            .filter((intersect) => !intersect.object.name.includes('decal-'))

        const intersectedObject = filteredIntersects[0]?.object;
        const intersectedPoint = filteredIntersects[0]?.point;
        const intersectedFace = filteredIntersects[0]?.face;
        const rayDirection = this.raycaster.ray.direction

        const tolerance = isVertical() ? 0.05 : 0.01;

        // const normal = intersectedFace?.normal.clone();
        // if (normal) {
        //     normal.transformDirection( intersectedObject.matrixWorld );
        //     this.helperVector3.copy(new THREE.ArrowHelper(normal.clone(), intersectedPoint.clone(), 0.2, new THREE.Color(0, 255, 0)))
        // }

        if (
            mode === Modes.DecalOnTexture
            && (intersectedObject?.type === 'Mesh' || intersectedObject?.type === 'SkinnedMesh')
            && (this.pointerDownPosition.distanceTo(this.pointerUpPosition) < tolerance)
            && intersectedPoint
            && intersectedFace
            ) {
            this.persistDecal(intersectedObject as THREE.Mesh, intersectedPoint, intersectedFace, rayDirection);
        }
    }

    private onPointerDown = () => {
        this.pointerDownPosition.copy( this.pointer );
        this.raycaster.setFromCamera( this.pointer, this.camera );

        // calculate objects intersecting the picking ray
        const intersects = this.raycaster.intersectObjects( this.scene.children, true );
        const pickedMaterial = StateManager.getInstance().getState(PICKED_MATERIAL);
        const mode = StateManager.getInstance().getState(MODE);

        const filteredIntersects = intersects
            .filter((intersect) => !intersect.object.name.includes('decal-'))

        if (!(filteredIntersects[0]?.object instanceof THREE.Mesh)) {
            return;
        }

        const object = (filteredIntersects[0]?.object instanceof THREE.Mesh) ? filteredIntersects[0]?.object : null;
        const intersectedMaterial = object?.material;

        if (!isSingleMaterial(intersectedMaterial)) {
            return;
        }

        if ((mode === Modes.ReplaceTexture || mode === Modes.AdjustColor) && intersectedMaterial !== pickedMaterial) {
            StateManager.getInstance().setState(UNPICKED_MATERIAL, pickedMaterial);
            StateManager.getInstance().setState(PICKED_MATERIAL, intersectedMaterial);
        }
    }

    private hoverPiece = () => {
        this.raycaster.setFromCamera( this.pointer, this.camera );

        // calculate objects intersecting the picking ray
        const intersects = this.raycaster.intersectObjects( this.scene.children, true );
        const hoverMaterial = StateManager.getInstance().getState(HOVERED_MATERIAL);
        const mode = StateManager.getInstance().getState(MODE);

        const object = (intersects[0]?.object instanceof THREE.Mesh) ? intersects[0]?.object : null;
        const intersectedMaterial = object?.material;

        const intersectedObject = intersects[0]?.object;
        const intersectedPoint = intersects[0]?.point;
        const intersectedFace = intersects[0]?.face;
        const rayDirection = this.raycaster.ray.direction
        
        // const normal = intersectedFace?.normal
        // // update the props for helperArrow1
        // this.helperVector1.copy(new THREE.ArrowHelper(this.raycaster.ray.direction.clone(), this.camera.position.clone(), 0.2, new THREE.Color(255, 0, 0)))
        // if (normal) {
        //     normal.transformDirection( intersectedObject.matrixWorld );
        //     this.helperVector2.copy(new THREE.ArrowHelper(normal.clone(), intersectedPoint.clone(), 0.2, new THREE.Color(0, 0, 255)))
        // }

        if ((mode === Modes.ReplaceTexture || mode === Modes.AdjustColor) && hoverMaterial !== intersectedMaterial) {
            StateManager.getInstance().setState(HOVERED_MATERIAL, intersectedMaterial);
            StateManager.getInstance().setState(UNHOVERED_MATERIAL, hoverMaterial);
        }


        if (
            mode === Modes.DecalOnTexture
            && intersectedObject
            && intersectedPoint
            && intersectedFace
            // && ( !this.prevPointer || this.prevPointer.distanceTo(this.pointer) > 0.05 )
        ) {
            this.hoverDecal(intersectedObject as THREE.Mesh, intersectedPoint, intersectedFace, rayDirection);
            this.prevPointer = this.pointer.clone();
        }

        if (intersects.length === 0 && this.hoverDecalMesh) {
            this.scene.remove(this.hoverDecalMesh);
            this.hoverDecalMesh.geometry.dispose();
            this.hoverDecalMesh = null;
        }
    }

    private subscribeToStateChanges() {
        this.stateSubscriptions.push(StateManager.getInstance().subscribe(GTLF_FILE, async () => {
            const gltfFile = StateManager.getInstance().getState(GTLF_FILE);
            /**
             * Load the gltf model
             */
            loadGltfModel(gltfFile).then((model) => {
                StateManager.getInstance().setState(IS_LOADING, true);
                setTimeout(async () => {
                    await this.handleAddingGltfModel(model)
                    StateManager.getInstance().setState(IS_LOADING, false);
                }, 5)
            })
        }))

        this.stateSubscriptions.push(StateManager.getInstance().subscribe(HOVERED_MATERIAL, this.handleMaterialHover.bind(this)))
        this.stateSubscriptions.push(StateManager.getInstance().subscribe(UNHOVERED_MATERIAL, this.handleMaterialUnhover.bind(this)))

        this.stateSubscriptions.push(StateManager.getInstance().subscribe(PICKED_MATERIAL, this.handleMaterialPick.bind(this)))
        this.stateSubscriptions.push(StateManager.getInstance().subscribe(UNPICKED_MATERIAL, this.handleMaterialUnpick.bind(this)))

        this.stateSubscriptions.push(StateManager.getInstance().subscribe(IMAGE_FILE, this.handleImageSelection.bind(this)))
        this.stateSubscriptions.push(StateManager.getInstance().subscribe(DECAL_IMAGE_FILE, this.handleDecalImageSelection.bind(this)))

        this.stateSubscriptions.push(StateManager.getInstance().subscribe(CUSTOM_TEXTURE_PROP, this.debouncedHandleCustomTexturePropertyChange.bind(this)))

        this.stateSubscriptions.push(StateManager.getInstance().subscribe(CUSTOM_TEXTURE_FLIP, this.handleCustomTextureFlip.bind(this)))

        this.stateSubscriptions.push(StateManager.getInstance().subscribe(MODE, this.handleModeChanged.bind(this)))
    }

    private handleModeChanged = () => {
        const mode = StateManager.getInstance().getState(MODE);

        if (mode !== Modes.ReplaceTexture && mode !== Modes.AdjustColor) {
            // unpick the object
            StateManager.getInstance().setState(UNPICKED_MATERIAL, StateManager.getInstance().getState(PICKED_MATERIAL));
            StateManager.getInstance().setState(PICKED_MATERIAL, null);
        }
    }

    private handleCustomTexturePropertyChange = () => {
        const {
            scale: customTextureScale,
            rotation: customTextureRotation,
            offset: customTextureOffset,
            colorFactor: customTextureColorFactor,
            roughness: customTextureRoughness,
            metalness: customTextureMetalness,
        } = StateManager.getInstance().getState(CUSTOM_TEXTURE_PROP) as CustomTextureProps;
        // ! This assumption might not be true
        const pickedMaterial = StateManager.getInstance().getState(PICKED_MATERIAL) as THREE.MeshStandardMaterial;
        const textureSet = StateManager.getInstance().getState(CUSTOM_TEXTURE_SET);

        if (!pickedMaterial) {
            return;
        }

        if (customTextureRoughness !== undefined) {
            pickedMaterial.roughness = customTextureRoughness;
        }

        if (customTextureMetalness !== undefined) {
            pickedMaterial.metalness = customTextureMetalness;
        }

        const canvasTexture = pickedMaterial.map;

        if (!canvasTexture) {
            return;
        }

        // redraw the image on the original canvas with the new rotation
        const canvas = canvasTexture.image as HTMLCanvasElement;
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            return;
        }

        // clear the canvas and redraw the image with the new rotation
        const originalImageMap = StateManager.getInstance().getState(ORIGIANL_IMAGE_MAP)
        const originalImage = originalImageMap.get(pickedMaterial.uuid)

        const originalMaterialTexturesMap = StateManager.getInstance().getState(ORIGINAL_MATERIAL_TEXTURES_MAP)
        const originalTexture = originalMaterialTexturesMap.get(pickedMaterial.id);

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.save();
        
        if (originalImage) {
            if (
                !textureSet
                || customTextureRotation === undefined
                || customTextureScale === undefined
                || customTextureOffset === undefined
            ) {
                return;
            }

            drawPattern(
                canvas,
                ctx,
                originalImage,
                customTextureRotation,
                customTextureScale,
                customTextureOffset.x * (canvas.width/2),
                customTextureOffset.y * (canvas.height/2),
            );
            drawColorFactor(
                ctx,
                canvas.width,
                canvas.height,
                customTextureColorFactor,
            );
        } else if (originalTexture) {
            drawPattern(canvas, ctx, originalTexture, 0, 1, 0, 0);
            drawColorFactor(
                ctx,
                canvas.width,
                canvas.height,
                customTextureColorFactor,
            );
        } else {
            ctx.fillStyle = customTextureColorFactor;
            ctx.fillRect(0, 0, canvas.width, canvas.height);
        }
       

        // update the canvas clone
        const canvasClone = this.originalCanvasClone.get(pickedMaterial.uuid);

        if (canvasClone) {
            canvasClone.getContext('2d')!.drawImage(canvas, 0, 0);
        }

        // drawBestFit(canvas, ctx, customTextureRotation, originalImage);
        ctx.restore();

        this.currentColorFactor.set(pickedMaterial.uuid, customTextureColorFactor);
        
        canvasTexture.needsUpdate = true;
    }

    private debouncedHandleCustomTexturePropertyChange = _.debounce(
        this.handleCustomTexturePropertyChange.bind(this),
        50,
        {
            leading: false,
            trailing: true,
        }
    );

    private handleCustomTextureFlip = () => {
        const customTextureFlip = StateManager.getInstance().getState(CUSTOM_TEXTURE_FLIP);
        const pickedMaterial = StateManager.getInstance().getState(PICKED_MATERIAL) as THREE.MeshStandardMaterial;
        const textureSet = StateManager.getInstance().getState(CUSTOM_TEXTURE_SET);

        if (!textureSet) {
            return;
        }

        (pickedMaterial.map as THREE.Texture).flipY = customTextureFlip;
        (pickedMaterial.map as THREE.Texture).needsUpdate = true;
    }

    private createCanvas = (size: {width: number, height: number}) => {
        const canvas = document.createElement('canvas');

        canvas.width = size.width;
        canvas.height = size.height;
        return canvas;
    }

    private handleImageSelection = () => {
        const imageFile = StateManager.getInstance().getState(IMAGE_FILE);
        const url = URL.createObjectURL(imageFile);
        const image = new Image();
        image.src = url;

        StateManager.getInstance().setState(IS_LOADING, true);
        image.onload = () => {
            StateManager.getInstance().setState(IS_LOADING, false);
            // ! this assumption might not be correct
            const pickedMaterial = StateManager.getInstance().getState(PICKED_MATERIAL) as THREE.MeshStandardMaterial;

            // replace the texture of the picked object
            const canvasTexture = pickedMaterial.map as THREE.Texture;
            const originalImageMap = StateManager.getInstance().getState(ORIGIANL_IMAGE_MAP)
            originalImageMap.set(pickedMaterial.uuid, image);

            // set the state
            StateManager.getInstance().setState(CUSTOM_TEXTURE_SET, true);
            StateManager.getInstance().setState(UNPICKED_MATERIAL, pickedMaterial);
            StateManager.getInstance().setState(PICKED_MATERIAL, pickedMaterial);

            StateManager.getInstance().setState(CUSTOM_TEXTURE_PROP, {
                scale: 1,
                rotation: 0,
                offset: {x: 0, y: 0},
                flip: canvasTexture.flipY,
                colorFactor: '#ffffff',
                roughness: undefined,
                metalness: undefined,
            } as CustomTextureProps);

            StateManager.getInstance().setState(CUSTOM_TEXTURE_FLIP, canvasTexture.flipY)
        }
    }

    private handleDecalImageSelection = () => {
        const imageFile = StateManager.getInstance().getState(DECAL_IMAGE_FILE);
        const url = URL.createObjectURL(imageFile);
        const image = new Image();
        image.src = url;
        
        StateManager.getInstance().setState(IS_LOADING, true);
        image.onload = () => {
            StateManager.getInstance().setState(IS_LOADING, false);
            this.decalImage = image;
            const material = createDecalMaterial(image);

            if (!material) {
                return;
            }

            this.decalMaterial = material;
        }
    }

    private async handleAddingGltfModel(gltfModel: THREE.Group) {
        // create a container to hold the gltf model
        this.modelContainer = new THREE.Object3D();
        this.modelContainer.name = 'gltfModelContainer';

        const childrenTraversalPromises: Array<Promise<void>> = []

        const wasMaterialProcess = new Map<number, boolean>();
        // convert all textures to canvas texture and add a blank canvas texture if the model has no texture
        gltfModel.traverse(async (child) => {
            const promise = new Promise<void>(async (resolve) => {
                if (child instanceof THREE.Mesh) {

                    
                    const material = child.material as THREE.MeshStandardMaterial;
                    if (wasMaterialProcess.has(material.id)) {
                        resolve();
                        return;
                    }

                    wasMaterialProcess.set(material.id, true);
    
                    let canvas = null
                    if (material.map) {
                        // paing the original image on the canvas
                        const imageBitmap = material.map.image as ImageBitmap;
                        const image = await loadImageFromBitmap(imageBitmap)
                        canvas = this.createCanvas({ width: image.width, height: image.height });
                        drawPattern(canvas, canvas.getContext('2d')!, image, 0, 1, 0, 0);
                        const originalMaterialTexturesMap = StateManager.getInstance().getState(ORIGINAL_MATERIAL_TEXTURES_MAP)
                        originalMaterialTexturesMap.set(material.id, image);
                    } else {
                        canvas = this.createCanvas({ width: 2048, height: 2048 })
    
                        // fill the canvas with the same color as the original material color and transparency
                        canvas.getContext('2d')!.fillStyle = `rgba(${material.color.r * 255}, ${material.color.g * 255}, ${material.color.b * 255})`;
    
                        // fill with 50% transpratent black
                        // canvas.getContext('2d')!.fillStyle = 'rgba(0, 0, 0, 0.5)';
    
                        canvas.getContext('2d')!.fillRect(0, 0, canvas.width, canvas.height);
                        this.currentColorFactor.set(material.uuid, `rgba(${material.color.r * 255}, ${material.color.g * 255}, ${material.color.b * 255})`);
                        material.color = new THREE.Color(1, 1, 1);
                    }
    
                    const canvasTexture = new THREE.CanvasTexture(canvas);
                    canvasTexture.wrapS = THREE.RepeatWrapping;
                    canvasTexture.wrapT = THREE.RepeatWrapping;
                    canvasTexture.flipY = false;
                    canvasTexture.center.set(0.5, 0.5);

                    if (material.map) {
                        canvasTexture.encoding = material.map.encoding
                        canvasTexture.format = material.map.format
                        canvasTexture.generateMipmaps = material.map.generateMipmaps
                    }
    
                    const duplicateCanvas = this.createCanvas({ width: canvas.width, height: canvas.height });
                    duplicateCanvas.getContext('2d')!.drawImage(canvas, 0, 0);
                    this.originalCanvasClone.set(material.uuid, duplicateCanvas);
    
                    material.map = canvasTexture;
                    material.normalScale.set(0.4, 0.4);
                    material.needsUpdate = true;
    
                    StateManager
                    .getInstance()
                    .setState(
                        MATERIAL_LIST,
                        [...(StateManager.getInstance().getState(MATERIAL_LIST) || []), material]
                    );
                }

                resolve()
            })

            childrenTraversalPromises.push(promise)
        });

        await Promise.all(childrenTraversalPromises)

        // scale the gltf model to fit the container
        const gltfBoundingBox = new THREE.Box3().setFromObject(gltfModel);
        this.originalGltfSize = gltfBoundingBox.getSize(new THREE.Vector3()).length();
        gltfModel.scale.multiplyScalar(TARGET_MODE_SIZE / this.originalGltfSize);

        // add the model to the container
        this.modelContainer.add(gltfModel);
        this.scene.add(this.modelContainer);
        
        // place the camera so that the model is in view
        const containerBoundingBox = new THREE.Box3().setFromObject(this.modelContainer);
        const containerCenter = containerBoundingBox.getCenter(new THREE.Vector3())
        const containerSize = containerBoundingBox.getSize(new THREE.Vector3()).length()

        // set the camera to frame the box
        this.camera.position.copy(containerCenter)
        this.camera.position.x += containerSize
        this.camera.position.y += containerSize
        this.camera.position.z += containerSize
        this.camera.lookAt(containerCenter)

        // update the trackball controls to handle the new size
        this.controls.maxDistance = containerSize * 2
        this.controls.target.copy(containerCenter)
        this.controls.update()
    }

    private handleMaterialUnhover = () => {
        const hoveredMaterial = StateManager.getInstance().getState(UNHOVERED_MATERIAL);
        const pickedMaterial = StateManager.getInstance().getState(PICKED_MATERIAL);
    
        if (!hoveredMaterial) {
            return;
        }

        // if the hovered object is the same as the picked object,
        // then we don't need to do anything
        if (pickedMaterial?.uuid === hoveredMaterial?.uuid) {
            return;
        }

        // restore the original material of the object
        if (isSingleMaterial(hoveredMaterial)) {
            this.unhighlightMaterial(hoveredMaterial as THREE.Material);
        }
    }


    private handleMaterialHover = () => {
        const hoveredMaterial = StateManager.getInstance().getState(HOVERED_MATERIAL);
        const pickedMaterial = StateManager.getInstance().getState(PICKED_MATERIAL);
        
        if (!hoveredMaterial) {
            return;
        }

        // if the hovered object is the same as the picked object,
        // then we don't need to do anything
        if (pickedMaterial?.uuid === hoveredMaterial?.uuid) {
            return;
        }
        
        // change the color of the hovered object
        this.highlighMaterialWithColor(hoveredMaterial, 0xffffff);
    }

    private handleMaterialUnpick = () => {
        const unpickMaterial = StateManager.getInstance().getState(UNPICKED_MATERIAL) as THREE.Material;
    
        if (!unpickMaterial) {
            return;
        }

        // restore the original material of the object
        this.unhighlightMaterial(unpickMaterial);
    }

    private handleMaterialPick = () => {
        const pickedMaterial = StateManager.getInstance().getState(PICKED_MATERIAL) as THREE.MeshStandardMaterial;

        if (!pickedMaterial) {
            return;
        }

        // change the color of the hovered object
        // dark yellow
        this.highlighMaterialWithColor(pickedMaterial, 0xbbbbbb);

        // set the state
        const texture = (pickedMaterial?.map as THREE.Texture);

        StateManager.getInstance().setState(CUSTOM_TEXTURE_PROP, {
            ...StateManager.getInstance().getState(CUSTOM_TEXTURE_PROP),
            colorFactor: this.currentColorFactor.get(pickedMaterial.uuid) || '#ffffff',
            roughness: pickedMaterial.roughness ?? 0,
            metalness: pickedMaterial.metalness ?? 0,
        } as CustomTextureProps);

        if (!texture) {
            return;
        }
    }

    private unhighlightMaterial(material: THREE.Material) {
        const color = this.currentColorFactor.get(material.uuid)

        if (material instanceof THREE.MeshStandardMaterial) {
            material.emissive.setHex(0x000000);
            material.emissiveIntensity = 0.0;
        } else {
            material = new THREE.MeshStandardMaterial({
                color,
                roughness: 0.5,
                metalness: 0.5,
            });
        }
    }

    private highlighMaterialWithColor(material: THREE.Material, color: number) {
        if (!material) {
            console.error('Hovered object has no material. This is not supported.');
            return;
        }

        if (material instanceof THREE.MeshStandardMaterial) {
            material.emissive.setHex(color);
            material.emissiveIntensity = 0.12;
        } else {
            material = new THREE.MeshStandardMaterial({
                color,
                roughness: 0.5,
                metalness: 0.5,
            });
        }
    }

    private persistDecal = (
        intersectedObject: THREE.Mesh,
        intersectedPoint: THREE.Vector3,
        intersectedFace: THREE.Face,
        rayDirection: THREE.Vector3
    ) => {
        // find whether the intersected face is a front or back face
        const normal = intersectedFace.normal.clone();
        normal.transformDirection( intersectedObject.matrixWorld );
        const side = normal.dot(rayDirection) > 0 ? THREE.BackSide : THREE.FrontSide;

        const decalScale = StateManager.getInstance().getState(DECAL_SCALE);
        const decalRotation = StateManager.getInstance().getState(DECAL_ROTATION);
        const decalGeometry = createDecalGeometry(
            intersectedObject,
            intersectedPoint,
            intersectedFace,
            decalScale,
            decalRotation
        );

        if (!this.decalMaterial) {
            return;
        }

        this.decalMaterial.side = side;
        
        const decal = createDecalMesh(
            this.decalMaterial,
            decalGeometry,
            intersectedObject,
            intersectedFace,
            true,
            side
        )

        this.modelContainer?.add(decal);
    }

    private hoverDecal = (
        intersectedObject: THREE.Mesh,
        intersectedPoint: THREE.Vector3,
        intersectedFace: THREE.Face,
        rayDirection: THREE.Vector3
    ) => {
        // find whether the intersected face is a front or back face
        const normal = intersectedFace.normal.clone();
        normal.transformDirection( intersectedObject.matrixWorld );
        const side = normal.dot(rayDirection) > 0 ? THREE.BackSide : THREE.FrontSide;

        const decalScale = StateManager.getInstance().getState(DECAL_SCALE);
        const decalRotation = StateManager.getInstance().getState(DECAL_ROTATION);
        const flipY = StateManager.getInstance().getState(DECAL_FLIP);
        const decalGeometry = createDecalGeometry(
            intersectedObject,
            intersectedPoint,
            intersectedFace,
            decalScale,
            decalRotation
        );

        if (this.decalMaterial) {
            this.decalMaterial.side = side;
            this.decalMaterial.polygonOffsetFactor = side === THREE.FrontSide ? -4 : 4;
        }

        // if no decal mesh exists, then we need to create a new one and add it to the scene
        if (!this.hoverDecalMesh) {
            if (!this.decalMaterial) {
                return;
            }

            this.hoverDecalMesh = createDecalMesh(
                this.decalMaterial,
                decalGeometry,
                intersectedObject,
                intersectedFace,
                false,
                side
            );
            this.scene.add(this.hoverDecalMesh);
        }

        // if the decal mesh already exists, then we need to update its geometry
        if (this.hoverDecalMesh) {
            this.hoverDecalMesh.geometry = decalGeometry;

            this.hoverDecalMesh.renderOrder = intersectedObject.renderOrder + 1;
            this.hoverDecalMesh.frustumCulled = false;

            // flip the decal texture if needed
            const materialMap = (this.hoverDecalMesh.material as THREE.MeshStandardMaterial).map;
            if (materialMap) {
                materialMap.flipY = flipY
            }

            if (this.decalMaterial?.map) {
                this.decalMaterial.map.needsUpdate = true;
            }
        }
    }

    /**
     * Start animation
     */
    animate = () => {
        requestAnimationFrame(this.animate.bind(this))
        // required if controls.enableDamping or controls.autoRotate are set to true
        this.controls.update();
        this.renderer.render(this.scene, this.camera)
    }

    saveGltfModel = () => {
        const gltfExporter = new GLTFExporter();

        // find and clone the model container gltfModelContainer
        const originalNames = new Map<string, string>();

        this.scene?.traverse((child) => {
            // replace the names with uinique names because these are retained after cloning
            const uuid = THREE.MathUtils.generateUUID();
            originalNames.set(uuid, child.name);
            child.name = uuid
        });

        const clonedScene = SkeletonUtils.clone(this.scene)

        // set the old uuids to the new objects and the original names
        clonedScene?.traverse((child) => {
            const originalChild = this.modelContainer?.getObjectByName(child.name);
            child.uuid = originalChild?.uuid || child.uuid;
            child.name = originalNames.get(child.name) || child.name;

            if (child instanceof THREE.Mesh && originalChild instanceof THREE.Mesh && child.material) {
                child.material.uuid = originalChild?.material.uuid || child.material.uuid;
            }
        });

        // reset the original names of the original scene
        this.scene?.traverse((child) => {
            child.name = originalNames.get(child.name) || child.name;
        });

        if (!clonedScene) {
            console.error('Could not find model container');
            return;
        }

        const highlightedMaterial = StateManager.getInstance().getState(PICKED_MATERIAL);

        // replace the materials with the original materials
        clonedScene.traverse((child) => {
            const childMaterial = child instanceof THREE.Mesh && isSingleMaterial(child.material) ? child.material as THREE.Material : null;
            if (childMaterial) {
                this.unhighlightMaterial(childMaterial);
            }
        });

        // get the gltf container from the cloned scene
        const gltfContainer = clonedScene.getObjectByName('gltfModelContainer');
        // scale it back to the original size
        if (gltfContainer && this.originalGltfSize) {
            gltfContainer.scale.multiplyScalar(this.originalGltfSize / TARGET_MODE_SIZE);
        }

        // export the model
        gltfExporter.parse(clonedScene, (result: any) => {
            if (result instanceof ArrayBuffer) {
                this.saveArrayBuffer(result, 'model.glb');
            } else {
                const output = JSON.stringify(result, null, 2);
                this.saveString(output, 'model.gltf');
            }
        }, (e) => {
            console.log('error: ', e);
        }, {
            onlyVisible: false,
            binary: true,
        });

        // highlight the picked material again
        if (highlightedMaterial) {
            this.highlighMaterialWithColor(highlightedMaterial, 0xbbbbbb);
        }

        // dispose the cloned model container
        clonedScene.traverse((child) => {
            if (child.type === 'Mesh') {
                (child as THREE.Mesh).geometry.dispose();
            }
        });
    }

    private saveArrayBuffer(buffer: ArrayBuffer, filename: string) {
        !isInIframe() && this.download(new Blob([buffer], { type: 'application/octet-stream' }), filename);
        this.sendToParent(new Blob([buffer], { type: 'application/octet-stream' }));
    }

    private saveString(text: string, filename: string) {
        !isInIframe() && this.download(new Blob([text], { type: 'text/plain' }), filename);
        this.sendToParent(new Blob([text], { type: 'text/plain' }));
    }

    private sendToParent = (blob: Blob) => {
        window.parent.postMessage({
            type: 'FINAL_GLB',
            value: blob,
        }, '*');
    }

    private download(blob: Blob, filename: string) {
        const link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link); // Firefox workaround, see #6594

        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();

        // URL.revokeObjectURL( url ); breaks Firefox...
    }

    destroyScene() {
        this.scene.remove(...this.scene.children);
        this.renderer.dispose();
        this.controls.dispose();
        this.stateSubscriptions.forEach((unsubscribe) => unsubscribe());

        window.removeEventListener('resize', this.onWindowResize.bind(this));
        this.canvas.removeEventListener('pointermove', this.onPointerMove.bind(this));
        this.canvas.removeEventListener('pointerdown', this.onPointerDown.bind(this));
        this.canvas.removeEventListener('click', this.onPointerUp.bind(this));
    }

}

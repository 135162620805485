import './ColorInput.css'

interface ColorInputProps {
    value: string;
    onChange: (value: string) => void;
}

export const ColorInput = ({
    onChange,
    value
}: ColorInputProps) => {
    return <>
    <div className="color-picker">
    <input type="color"
        value={value}
        onChange={(e) => onChange(e.target.value)} className="color-picker-input"
    />
    </div>
    </>
}
import { useEffect, useState } from "react";
import { AccordionRow, DropDown } from "../../components"
import { FormField } from "../../components/form-field"
import './SelectMaterialForm.css'
import { StateManager } from "../../util";
import { HOVERED_MATERIAL, MATERIAL_LIST, PICKED_MATERIAL, UNPICKED_MATERIAL } from "../../scene/SceneManager";
import { Material } from "three";

export const SelectMaterialForm = () => {
    const [curMaterial, setCurMaterial] = useState<Material>();
    const [options, setOptions] = useState<string[]>([]);
    const [materialList, setMaterialList] = useState<Material[]>([]);

    useEffect(() => {
        const unsubMaterialList = StateManager.getInstance().subscribe(MATERIAL_LIST, () => {
            const materials: Material[] = StateManager.getInstance().getState(MATERIAL_LIST)
            const options = [...(materials?.map((material) => material.name) || []), 'None']
            setOptions(options)
            setMaterialList(materials)
        })

        const unsubPickedMesh = StateManager.getInstance().subscribe(PICKED_MATERIAL, () => {
            const material: Material | null = StateManager.getInstance().getState(PICKED_MATERIAL)
            setCurMaterial(material ?? undefined)
        })

        return () => {
            unsubMaterialList()
            unsubPickedMesh()
        }
    }, [])

    const handleMeshPick = (name: string) => {
        const material = name !== 'None' ? materialList.find((material) => material.name === name) : null
        const currentPickedMaterial = StateManager.getInstance().getState(PICKED_MATERIAL)


        StateManager.getInstance().setState(UNPICKED_MATERIAL, currentPickedMaterial)
        StateManager.getInstance().setState(HOVERED_MATERIAL, material)
        StateManager.getInstance().setState(PICKED_MATERIAL, material)
      }

    return <div className='select-material-container'>
        <AccordionRow>
            <FormField label="Material">
            <DropDown
                value={curMaterial?.name ?? 'None'}
                options={options}
                onChange={handleMeshPick}
            />
            </FormField>
        </AccordionRow>
    </div>
}
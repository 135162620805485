export const loadImageFromFile = async (file: File): Promise<HTMLImageElement> => {
    const url = URL.createObjectURL(file);
    const image = new Image();
    image.src = url;

    return new Promise((resolve, reject) => {
        image.onload = () => {
            resolve(image);
        };

        image.onerror = (error) => {
            reject(error);
        };
    });
}
import './ButtonFileInput.css'

interface ButtonFileInputProps {
    label: string;
    onChange: (file: File) => void;
    accept: string;
}

export const ButtonFileInput = ({
    label,
    onChange,
    accept
}: ButtonFileInputProps) => {
    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0]
            onChange(file)
        }
    }

    return <>
        <label className="button-file-input">
            <input className="file-input-element" type="file" accept={accept} onChange={onFileChange} />
            {label}
        </label>
    </>
}
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { StateManager } from '../util';
import { IS_LOADING } from './SceneManager';

export const loadGltfModel = async (gltfFile: File): Promise<THREE.Group> => {
    const url = URL.createObjectURL(gltfFile);

    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath( 'https://www.gstatic.com/draco/v1/decoders/' );
    const loader = new GLTFLoader()
    loader.setDRACOLoader( dracoLoader );

    return new Promise((resolve, reject) => {
        StateManager.getInstance().setState(IS_LOADING, true)
        loader.load(
            url,
            ( gltf ) => {
                StateManager.getInstance().setState(IS_LOADING, false)
                if (gltf.scene) {
                    resolve(gltf.scene)
                } else {
                    reject('No scene found')
                }
            }
        );
    })
}

import React from 'react';
import './UploadIcon.css';

// import the icon svg file
import { ReactComponent as UploadIconSvg } from './upload-icon.svg';

export const UploadIcon = () => {
    return <div className="upload-icon">
        <UploadIconSvg className='icon' />
    </div>
}
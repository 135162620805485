import { useEffect, useState } from "react";
import { AccordionRow, ButtonFileInput, RangeInput, ToggleInput } from "../../components"
import { FormField } from "../../components/form-field"
import { UploadIcon } from "../../components/icons";
import { StateManager, loadImageFromFile, shortenFloat, toDegrees, toRadians } from "../../util";
import { DECAL_FLIP, DECAL_IMAGE_FILE, DECAL_ROTATION, DECAL_SCALE, DECAL_SCALE_CONSTS, ROTATION_VALUE_CONSTS } from "../../scene/SceneManager";
import './UploadStickerForm.css'

export const UploadStickerForm = () => {
    const [decalImageFile, setDecalImageFile] = useState<File | null>(null)
    const [flipTexture, setFlipTexture] = useState<boolean>(false);
    const [rotation, setRotation] = useState<number>(0);
    const [scale, setScale] = useState<number>(0.2);
    const [imageUrl, setImageUrl] = useState('./assets/transparent.png')

    useEffect(() => {
        if (decalImageFile) {
            StateManager.getInstance().setState(DECAL_IMAGE_FILE, decalImageFile)
        }
      }, [decalImageFile])

    useEffect(() => {
        const unsubDecalRotation = StateManager.getInstance().subscribe(DECAL_ROTATION, () => {
            const value = StateManager.getInstance().getState(DECAL_ROTATION)
            setRotation(value)
        })
    
        const unsubDecalScale = StateManager.getInstance().subscribe(DECAL_SCALE, () => {
            const value = StateManager.getInstance().getState(DECAL_SCALE)
            setScale(value)
        })
    
        const unsubDecalFlip = StateManager.getInstance().subscribe(DECAL_FLIP, () => {
            const value = StateManager.getInstance().getState(DECAL_FLIP)
            setFlipTexture(value)
        })

        const unsbDecalImageFile = StateManager.getInstance().subscribe(DECAL_IMAGE_FILE, () => {
            const file = StateManager.getInstance().getState(DECAL_IMAGE_FILE)
            if (file) {
                loadImageFromFile(file).then((image) => {
                    setImageUrl(image.src)
                })
            }
        })

        return () => {
            unsubDecalRotation()
            unsubDecalScale()
            unsubDecalFlip()
            unsbDecalImageFile()
        }
    }, [])

    const handleDecalRotationChange = (val: number) => {
        StateManager.getInstance().setState(DECAL_ROTATION, val)
    }

    const handleDecalScaleChange = (val: number) => {
        StateManager.getInstance().setState(DECAL_SCALE, val)
    }

    const handleDecalFlipChange = (flip: boolean) => {
        StateManager.getInstance().setState(DECAL_FLIP, flip)
    }

    return <>
        <AccordionRow>
            <FormField label="Sticker" icon={<UploadIcon />}>
                <div className="decal-selector">
                    <div className="preview-decal-image-container">
                        <img className="decal-image-preview" src={imageUrl} alt="preview decal image" />
                    </div>
                    <ButtonFileInput label='Select Image' onChange={(file) => setDecalImageFile(file)} accept="image/*" />

                </div>
            </FormField>
        </AccordionRow>
        <AccordionRow>
            <FormField label='Rotation'>
                <RangeInput
                    step={toDegrees(ROTATION_VALUE_CONSTS.step)}
                    min={toDegrees(ROTATION_VALUE_CONSTS.min)}
                    max={toDegrees(ROTATION_VALUE_CONSTS.max)}
                    value={shortenFloat(toDegrees(rotation), 2)}
                    onChange={(value) => handleDecalRotationChange(toRadians(value))}
                />
            </FormField>
        </AccordionRow>
        <AccordionRow>
            <FormField label='Scale'>
                <RangeInput
                    step={DECAL_SCALE_CONSTS.step}
                    min={DECAL_SCALE_CONSTS.min}
                    max={DECAL_SCALE_CONSTS.max}
                    value={shortenFloat(scale, 2)}
                    onChange={(value) => handleDecalScaleChange(value)}
                />
            </FormField>
        </AccordionRow>
        <AccordionRow>
            <FormField label='Flip the texture'>
                <ToggleInput value={flipTexture} onChange={(value) => handleDecalFlipChange(value)} />
            </FormField>
        </AccordionRow>
    </>
}
export const loadImageFromBitmap = async (bitmap: ImageBitmap): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
  
      img.onload = () => {
        resolve(img);
      };
  
      img.onerror = (error) => {
        reject(error);
      };
  
      const canvas = document.createElement('canvas');
      canvas.width = bitmap.width;
      canvas.height = bitmap.height;
  
      const context = canvas.getContext('2d');
      context?.drawImage(bitmap, 0, 0);
  
      canvas.toBlob((blob) => {
        if (blob) {
          img.src = URL.createObjectURL(blob);
        } else {
          reject(new Error('Failed to convert ImageBitmap to Blob.'));
        }
      }, 'image/png');
    });
  }
  
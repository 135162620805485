import React, { useEffect, useState } from "react";
import './Accordion.css';

export interface AccordionItemProps<T> {
    title: string;
    renderTitle?: React.ReactNode;
    render?: React.ReactNode;
    type: 'expandable' | 'inline'
    key: T;
}

export interface AccordionProps<T> {
    items: AccordionItemProps<T>[];
    defaultActiveIndex?: number;
    onChange?: (index: number, key: T | null) => void;
}

const shouldRenderHeader = (items: AccordionItemProps<any>[]) => {
    return items.filter(item => item.type === 'expandable').length > 1;
}

export const Accordion = <T,>({ items, onChange, defaultActiveIndex }: AccordionProps<T>) => {
    const [activeIndex, setActiveIndex] = useState<number>(defaultActiveIndex ?? -1);

    const handleChange = (index: number) => {
        if (activeIndex === index) {
            setActiveIndex(-1);
            onChange && onChange(-1, null);
        } else {
            setActiveIndex(index);
            onChange && onChange(index, items[index].key);
        }
    }

    return (
        <div className="accordion-container">
            {items.map((item, index) => (
                <div key={index} className="tab-container">
                    {
                        shouldRenderHeader(items) && !item.renderTitle &&
                    <div className="tab-header" onClick={item.type === 'expandable' ? () => handleChange(index) : () => {}}>
                        <p>{item.title}</p>
                        {
                            activeIndex === index
                            ? <p>▲</p>
                            : <p>▼</p>
                        }
                    </div>
                    }
                    {
                        item.renderTitle &&
                        <div className="tab-header" onClick={item.type === 'expandable' ? () => handleChange(index) : () => {}}>
                            {item.renderTitle}
                        </div>
                    }
                    {
                        item.render && <div className={`tab-content ${(activeIndex === index) ? 'active' : 'inactive'}`}>
                            {item.render}
                        </div>
                    }
                </div>
            ))}
        </div>
    )
}
import React, { useEffect } from 'react';
import './RangeInput.css';
import { debounce } from '../../util';

interface RangeInputProps {
    value: number;
    onChange: (value: number) => void;
    min?: number;
    max?: number;
    step?: number;
}

export const RangeInput = ({
    value,
    onChange,
    max,
    min,
    step,
}: RangeInputProps) => {
    const rangeInputRef = React.useRef<HTMLInputElement>(null);
    const [inputValue, setInputValue] = React.useState<string>(`${value}`);

    const debouncedOnChange = debounce(() => {
        // use regex to check if input is a number either int or float negative or positive
        const regex = /^-?\d*\.?\d*$/;
        if (!regex.test(inputValue)) return;
        onChange(parseFloat(inputValue))
    }, 1200)

    useEffect(() => {
        debouncedOnChange(inputValue)
    }, [inputValue])

    useEffect(() => {
        const rangeInput = rangeInputRef.current;
        if (rangeInput === null) return;

        const handleInput = () => {
            const rangeInputMin = Number(rangeInput.min);
            const rangeInputMax = Number(rangeInput.max);
            const rangeInputValue = Number(rangeInput.value);
            const value = (rangeInputValue - rangeInputMin) / (rangeInputMax - rangeInputMin);

            rangeInput.style.background = `linear-gradient(to right, var(--secondary-color) 0%, var(--secondary-color) ${value * 100}%, var(--theme-background-color) ${value * 100}%, var(--theme-background-color) 100%)`;
        }

        rangeInput.addEventListener('input', handleInput);
        handleInput();

        return () => {
            rangeInput.removeEventListener('input', handleInput);
        }
    }, [value])


    return <>
        <input
            ref={rangeInputRef}
            className='range-input'
            type='range'
            min={min ?? 0}
            max={max ?? 1}
            step={step ?? 0.01}
            value={value}
            onChange={(e) => {
                setInputValue(e.target.value)
                onChange(parseFloat(e.target.value))
            }}
        />
        <input
            className='range-input-value'
            value={inputValue}
            onChange={(ev) => {
                setInputValue(ev.target.value)
            }}
        />
    </>
}
import { useEffect, useState } from "react";
import { AccordionRow, ButtonFileInput, RangeInput, ToggleInput } from "../../components"
import { FormField } from "../../components/form-field"
import { UploadIcon } from "../../components/icons";
import { StateManager, isSingleMaterial, shortenFloat, toDegrees, toRadians } from "../../util";
import {
    CUSTOM_TEXTURE_FLIP,
    CUSTOM_TEXTURE_PROP,
    CustomTextureProps,
    IMAGE_FILE,
    OFFSET_VALUE_CONSTS,
    ORIGIANL_IMAGE_MAP,
    ORIGINAL_MATERIAL_TEXTURES_MAP,
    PICKED_MATERIAL,
    ROTATION_VALUE_CONSTS,
    SCALE_VALUE_CONSTS
} from "../../scene/SceneManager";
import './UploadImageForm.css'

interface UploadImageFormProps {
    showWarning?: boolean;
}

const getTextureImageUrl = async () : Promise<string> => {
    const pickedMaterial = StateManager.getInstance().getState(PICKED_MATERIAL)
    
    const originalImageMap = StateManager.getInstance().getState(ORIGIANL_IMAGE_MAP)
    const originalMaterialTextureMap = StateManager.getInstance().getState(ORIGINAL_MATERIAL_TEXTURES_MAP)
    if (pickedMaterial) {
        if (!isSingleMaterial(pickedMaterial)) {
            return Promise.resolve('./assets/transparent.png')
        }
        const originalImage = originalImageMap.get(pickedMaterial.uuid) as HTMLImageElement || undefined
        const originalMaterialTexture = originalMaterialTextureMap.get(pickedMaterial.id) as HTMLImageElement || undefined

        if (originalImage) {
            return originalImage.src
        } else if (originalMaterialTexture) {
            return originalMaterialTexture.src
        }
    }

    return Promise.resolve('./assets/transparent.png')
}

export const UploadImageForm = ({
    showWarning,
}: UploadImageFormProps) => {
    const [flipTexture, setFlipTexture] = useState<boolean>(false);
    const [rotation, setRotation] = useState<number>(0);
    const [scale, setScale] = useState<number>(1);
    const [offsetX, setOffsetX] = useState<number>(0);
    const [offsetY, setOffsetY] = useState<number>(0);
    const [imageFile, setImageFile] = useState<File | null>(null)
    const [imageUrl, setImageUrl] = useState('./assets/transparent.png')

    useEffect(() => {
        if (imageFile) {
            StateManager.getInstance().setState(IMAGE_FILE, imageFile)
        }
    }, [imageFile])

    useEffect(() => {
        const unsubCustomProp = StateManager.getInstance().subscribe(CUSTOM_TEXTURE_PROP, () => {
            const {
                scale: customScale,
                rotation: customRotation,
                offset: customOffset,
            } = StateManager
            .getInstance()
            .getState(CUSTOM_TEXTURE_PROP) as CustomTextureProps

            setRotation(customRotation ?? rotation)
            setScale(customScale ?? scale)
            setOffsetX(customOffset?.x ??  offsetX)
            setOffsetY(customOffset?.y ?? offsetY)
        })

        const unsubFlip = StateManager.getInstance().subscribe(CUSTOM_TEXTURE_FLIP, () => {
            const value = StateManager.getInstance().getState(CUSTOM_TEXTURE_FLIP)
            setFlipTexture(value)
          })

          const unsubPick = StateManager.getInstance().subscribe(PICKED_MATERIAL, () => {
            getTextureImageUrl().then((imageUrl) => {
                setImageUrl(imageUrl)
            })
        })

        return () => {
            unsubCustomProp()
            unsubFlip()
            unsubPick()
        }
    }, [])

    useEffect(() => {
        getTextureImageUrl().then((imageUrl) => {
            setImageUrl(imageUrl)
        })
    })

    const handleRotationChange = (val: number) => {
        StateManager.getInstance().setState(CUSTOM_TEXTURE_PROP, {
          ...StateManager.getInstance().getState(CUSTOM_TEXTURE_PROP),
          rotation: val,
        } as CustomTextureProps)
      }
    
      const handleScaleChange = (val: number) => {
        StateManager.getInstance().setState(CUSTOM_TEXTURE_PROP, {
          ...StateManager.getInstance().getState(CUSTOM_TEXTURE_PROP),
          scale: val,
        } as CustomTextureProps)
      }
    
      const handleXOffsetChange = (val: number) => {
        const curOffser = StateManager.getInstance().getState(CUSTOM_TEXTURE_PROP).offset;
        StateManager.getInstance().setState(CUSTOM_TEXTURE_PROP, {
          ...StateManager.getInstance().getState(CUSTOM_TEXTURE_PROP),
          offset: {
            x: val,
            y: curOffser?.y
          },
        } as CustomTextureProps)
      }
    
      const handleYOffsetChange = (val: number) => {
        const curOffser = StateManager.getInstance().getState(CUSTOM_TEXTURE_PROP).offset;
        StateManager.getInstance().setState(CUSTOM_TEXTURE_PROP, {
          ...StateManager.getInstance().getState(CUSTOM_TEXTURE_PROP),
          offset: {
            x: curOffser?.x,
            y: val
          },
        } as CustomTextureProps)
      }

      const handleFlipTexture = (flip: boolean) => {
        StateManager.getInstance().setState(CUSTOM_TEXTURE_FLIP, flip)
      }
    
    return <>
        {
            showWarning
            ?
                <AccordionRow>
                    <span>Select a mesh to modify its image</span>
                </AccordionRow>
            : (
                <>
                    <AccordionRow>
                        <FormField label="Image" icon={<UploadIcon />}>
                            <div className="decal-selector">
                                <div className="preview-image-container">
                                    <img className="image-preview" src={imageUrl} alt="preview image" />
                                </div>
                                <ButtonFileInput label='Select Image' onChange={(file) => setImageFile(file)} accept="image/*" />
                            </div>
                        </FormField>
                    </AccordionRow>
                    <AccordionRow>
                        <FormField label='Rotation'>
                            <RangeInput
                                value={shortenFloat(toDegrees(rotation), 2)}
                                step={toDegrees(ROTATION_VALUE_CONSTS.step)}
                                min={toDegrees(ROTATION_VALUE_CONSTS.min)}
                                max={toDegrees(ROTATION_VALUE_CONSTS.max)}
                                onChange={(value) => handleRotationChange(toRadians(value))}
                            />
                        </FormField>
                    </AccordionRow>
                    <AccordionRow>
                        <FormField label='Scale'>
                            <RangeInput
                                step={SCALE_VALUE_CONSTS.step}
                                min={SCALE_VALUE_CONSTS.min}
                                max={SCALE_VALUE_CONSTS.max}
                                value={shortenFloat(scale, 2)}
                                onChange={(value) => handleScaleChange(value)}
                            />
                        </FormField>
                    </AccordionRow>
                    <AccordionRow>
                        <FormField label='Offset X'>
                            <RangeInput
                                step={OFFSET_VALUE_CONSTS.step}
                                min={OFFSET_VALUE_CONSTS.min}
                                max={OFFSET_VALUE_CONSTS.max}
                                value={shortenFloat(offsetX, 2)}
                                onChange={(value) => handleXOffsetChange(value)}
                            />
                        </FormField>
                    </AccordionRow>
                    <AccordionRow>
                        <FormField label='Offset Y'>
                            <RangeInput
                                step={OFFSET_VALUE_CONSTS.step}
                                min={OFFSET_VALUE_CONSTS.min}
                                max={OFFSET_VALUE_CONSTS.max}
                                value={shortenFloat(offsetY, 2)}
                                onChange={(value) => handleYOffsetChange(value)}
                            />
                        </FormField>
                    </AccordionRow>
                    <AccordionRow>
                        <FormField label='Flip the texture'>
                            <ToggleInput
                                value={flipTexture}
                                onChange={(value) => handleFlipTexture(value)}
                            />
                        </FormField>
                    </AccordionRow>
                </>
            )
        }
    </>
}
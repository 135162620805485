import React from 'react';
import './ToggleInput.css';

interface ToggleInputProps {
    value: boolean;
    onChange: (value: boolean) => void;
}

export const ToggleInput = ({
    onChange,
    value
}: ToggleInputProps) => {
    const uniqueId = React.useMemo(() => Math.random().toString(36).substring(2, 9), []);
    return <>
        <div className="toggle">
            <input
                type="checkbox"
                className="toggle-checkbox"
                id={`toggle-switch-${uniqueId}`}
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
            />
            <label htmlFor={`toggle-switch-${uniqueId}`} className="toggle-label" />
        </div>
    </>
}

import './FormField.css'

interface FormFieldProps {
    label?: string;
    icon?: React.ReactNode;
    children: React.ReactNode;
    id?: string;
}

export const FormField = ({
    children,
    icon,
    label,
    id,
}: FormFieldProps) => {
    return <div id={id} className="form-field-container">
        {label && <div className="form-field-label">{label}</div>}
        {icon && <div className="form-field-icon">{icon}</div>}
        <div className="form-field-content">{children}</div>
    </div>
}
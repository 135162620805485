export const debounce = (fn: (...args: any[]) => any , delay: number) => {
    // leading edge
    let timeout: NodeJS.Timeout | null = null;
    return (...args: any[]) => {
        if (!timeout) {
            fn(...args);
            return;
        }
        timeout = setTimeout(() => {
            timeout = null;
        }, delay);
    };
};
import { Portal } from 'react-portal';
import { useEffect, useRef } from "react";
import './DropDown.css';

interface DropDownProps {
    value: string;
    onChange: (value: string) => void;
    options: string[];
}

export const DropDown = ({
    onChange,
    options,
    value,
}: DropDownProps) => {
    const dropdownToggleRef = useRef<HTMLDivElement>(null);
    const dropdownMenuContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const dropdownToggle = dropdownToggleRef.current;
        const dropdownMenuContainer = dropdownMenuContainerRef.current;
        const dropdownOptions = document.querySelectorAll('.dropdown-option');
        const handlers: WeakMap<Element, () => void> = new WeakMap();

        if (dropdownToggle === null || dropdownMenuContainer === null) return;

        const dropdownToggleClick = (ev: MouseEvent) => {
            ev.stopPropagation();
            dropdownMenuContainer.style.display = dropdownMenuContainer.style.display !== 'block' ? 'block' : 'none';
            dropdownMenuContainer.style.top = `${dropdownToggle.getBoundingClientRect().bottom}px`;
            dropdownMenuContainer.style.left = `${dropdownToggle.getBoundingClientRect().left}px`;
        }

        const makeOptionClickHandler = (option: Element) => {
            return () => {
                const dropdownSelectedValue = dropdownToggle.querySelector('.dropdown-selected-value')
                
                if (dropdownSelectedValue === null) return;
    
                const selectedValue = option.textContent;
                dropdownSelectedValue.textContent = selectedValue;
                onChange(selectedValue ?? '');
                dropdownMenuContainer.style.display = 'none';
            }
        }

        const scrollHandler = () => {
            dropdownMenuContainer.style.display = 'none';
        }

        const outsideClickHandler = (ev: MouseEvent) => {
            if (dropdownToggle.contains(ev.target as Node)) return;
            dropdownMenuContainer.style.display = 'none';
        }

        window.addEventListener('scroll', scrollHandler);
        window.addEventListener('click', outsideClickHandler);

        dropdownToggle.addEventListener('click', dropdownToggleClick);

        dropdownOptions.forEach((option) => {
            const handler = makeOptionClickHandler(option);
            handlers.set(option, handler);
            option.addEventListener('click', handler);
        });

        return () => {
            dropdownToggle.removeEventListener('click', dropdownToggleClick);
            dropdownOptions.forEach((option) => {
                const handler = handlers.get(option);
                if (handler === undefined) return;
                option.removeEventListener('click', handler);
            });
        }
    }, [options])

    return <div className="dropdown">
        <div ref={dropdownToggleRef} className="dropdown-toggle">
        <span className="dropdown-selected-value">{value}</span>
        <span className="dropdown-icon">&#x25BE;</span>
        </div>
        <Portal>
            <div ref={dropdownMenuContainerRef} className="dropdown-menu-container">
                <ul className="dropdown-menu">
                    {
                        options.map((option) => {
                            return <li key={option} className="dropdown-option">{option}</li>
                        })
                    }
                </ul>
            </div>
        </Portal>
    </div>
}

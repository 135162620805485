/**
 * This singleton class is used to manage the state of the whole application
 * Any value that can be used by two different modules should be stored in the
 * state manager and read from it.
 */
export class StateManager {
    static instance: StateManager | null = null;
    state: { [id: string]: { val: any, cbs: Function[] } } = {};

    /**
     * @param id The id of the portion of state to subscribe to
     * @param cb A callback that will be called when the state of [id] has changed
     */
    subscribe(id: string, cb: Function): () => void {
        if (!this.state[id])
            this.state[id] = { val: undefined, cbs: [] }

        this.state[id].cbs = [...this.state[id].cbs, cb];

        return () => {
            this.state[id].cbs = this.state[id].cbs.filter((c) => c !== cb);
        }
    }

    /**
     * Returns the value of state at [id]
     * @param id The id of the portion of state to get its value
     * @returns 
     */
    getState(id: string) {
        if (!this.state[id])
            return null;

        return this.state[id].val;
    }

    /**
     * Update the value of state at [id]
     * @param id  The id of the portion of state to set its value
     * @param val The new value
     */
    setState(id: string, val: any) {
        if (!this.state[id])
            this.state[id] = { val: undefined, cbs: [] }

        this.state[id] = {
            ...this.state[id],
            val
        }

        this.state[id].cbs.forEach((cb) => {
            cb();
        });
    }

    setStateWithoutNotifying(id: string, val: any) {
        if (!this.state[id])
            this.state[id] = { val: undefined, cbs: [] }

        this.state[id] = {
            ...this.state[id],
            val
        }
    }

    static getInstance(): StateManager {
        if (!StateManager.instance) {
            StateManager.instance = new StateManager();
        }

        return StateManager.instance;
    }

    static destroyInstance() {
        StateManager.instance = null;
    }
}